import SingleZoneWrapper from '../components/SingleZoneWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsTablet } from '../../../../../hooks/devices/useIsTablet';
import React from 'react';

import { routes } from '../../../../../static/routes';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import Calendar from '../../../components/Calendar/Calendar';

const SingleZoneActivities = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();

  const parkingId = params.parkingId as string;
  const parkingZoneId = params.zoneId as string;
  const { isTablet } = useIsTablet();
  const submitBtnText = t('parkingSpotCalendar.shareSpotBtn');
  const submitBtnOnClick = () => {
    navigate(routes.parkingBasic.shareSpot(parkingId));
  };

  return (
    <SingleZoneWrapper>
      {!isTablet && (
        <div className="flex flex-col mb-8">
          <PrimaryButton onClick={submitBtnOnClick} className="w-fit self-end">
            {submitBtnText}
          </PrimaryButton>
        </div>
      )}
      <Calendar parkingId={parkingId} parkingZoneId={parkingZoneId} />
    </SingleZoneWrapper>
  );
};

export default SingleZoneActivities;
