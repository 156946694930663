import ParkingBasicWrapper from '../ParkingBasicWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../../../components/common/buttons/PrimaryButton';
import { useIsTablet } from '../../../../hooks/devices/useIsTablet';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../static/routes';
import Calendar from '../../components/Calendar/Calendar';

const Activities = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();

  const parkingId = params.parkingId as string;
  const { isTablet } = useIsTablet();
  const submitBtnText = t('parkingSpotCalendar.shareSpotBtn');
  const submitBtnOnClick = () => {
    navigate(routes.parkingBasic.shareSpot(parkingId));
  };
  return (
    <ParkingBasicWrapper>
      {!isTablet && (
        <div className="flex flex-col mb-8">
          <PrimaryButton onClick={submitBtnOnClick} className="w-fit self-end">
            {submitBtnText}
          </PrimaryButton>
        </div>
      )}
      <Calendar parkingId={parkingId} />
    </ParkingBasicWrapper>
  );
};

export default Activities;
