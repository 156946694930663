import { ApiParkingRuleDetails, IParkingRuleDetails } from '../../../types/api/ApiTypes';

export const transformParkingRuleDetails = (apiRule: ApiParkingRuleDetails): IParkingRuleDetails => {
  const transformedRule: IParkingRuleDetails = {
    id: apiRule.id,
    name: apiRule.name,
    groups: apiRule.groups,
    priceList: apiRule.price_list,
    parkingZone: apiRule.parking_zone,
    parkingSpots: apiRule.parking_spots,
    availabilities: apiRule.availabilities,
    validFrom: apiRule.valid_from,
    validTo: apiRule.valid_to,
  };

  return transformedRule;
};
