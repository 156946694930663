import useSingleParking from '../../../../hooks/api/parkings/useSingleParking';
import { useNavigate, useParams } from 'react-router-dom';
import useParkingSpotsDropdownOptions from '../../../../hooks/dopdownOptions/useParkingSpotsDropdownOptions';
import TitleWrapper from '../../../../components/wrappers/titleWrapper/TitleWrapper';
import ShareSpotForm from './components/ShareSpotForm';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../../components/common/buttons/BackButton';
import useShareSpot from '../../../../hooks/api/parkings/useShareSpot';
import { ApiShareParkingSpot } from '../../../../types/api/ApiTypes';
import { routes } from '../../../../static/routes';

const ShareSpot = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const parkingId = params.parkingId as string;
  const { parking } = useSingleParking({ parkingId });
  const { spotsDropdownOptions } = useParkingSpotsDropdownOptions({ parkingId });
  const { shareSpot, isLoading } = useShareSpot();

  const handleShareSpot = async (shareData: ApiShareParkingSpot) => {
    const newData: ApiShareParkingSpot = {
      ...shareData,
    };
    await shareSpot({ parkingId, shareData: newData });

    navigate(routes.parkingBasic.activities(parkingId));
  };

  const extraBreadcrumbs = [
    {
      text: parking?.name ?? '',
    },
  ];

  return (
    <TitleWrapper
      title={`${t('parkingDetails.title')}${!!parking?.name ? ` (${parking.name})` : ''}`}
      breadcrumbsProps={{ limit: 2, extraBreadcrumbs }}
    >
      <BackButton className="mt-8 mb-16 " />
      {!!parking && !!spotsDropdownOptions && (
        <ShareSpotForm parkingId={parkingId} onSubmitForm={handleShareSpot} isSubmitBtnLoading={isLoading} />
      )}
    </TitleWrapper>
  );
};

export default ShareSpot;
