import { Dayjs } from 'dayjs';
import { TypeFromConstObject } from '../Types';
import { Country } from '../countries/Countries';
import { CurrencyTypes } from '../currency/CurrencyTypes';
import { Weekday } from '../weekdays/Weekdays';

export interface LoginRequestType {
  phone_number: string;
  username: string;
  password: string;
}

export enum GroupPermission {
  AddUsers = 'add_user_userparkinggroup',
  GroupEdit = 'change_userparkinggroup',
  GroupDelete = 'delete_userparkinggroup',
  AssignPermission = 'permission_assign_userparkinggroup',
  RemoveUsers = 'remove_user_userparkinggroup',
  GroupPreview = 'view_userparkinggroup',
}

export type GroupPermissionsState = Record<GroupPermission, boolean>;

export interface IGroupUser {
  phone: string;
  email?: string;
  permissions: GroupPermission[];
  status?: string;
}

export interface ApiGroupUser {
  phone_number: string;
  email?: string;
  permissions: GroupPermission[];
  status?: string;
}

export interface ApiGroup {
  id: string;
  display_name: string;
  user_count: number;
  in_use: boolean;
  permissions: GroupPermission[];
  users: ApiGroupUser[];
}

export interface IGroup {
  id: string;
  displayName: string;
  userCount: number;
  inUse: boolean;
  permissions: GroupPermission[];
  users: IGroupUser[];
}
export interface ApiRuleGroup {
  id: string;
  name: string;
  user_count: number;
  in_use: boolean;
  permissions: GroupPermission[];
}

export interface IRuleGroup {
  id: string;
  name: string;
  userCount: number;
  inUse: boolean;
  permissions: GroupPermission[];
}

export interface ValidateUserPhoneRequestType {
  phone_number: string;
  group_id?: string;
}

export type ApiErrors = {
  code: string;
  message: string;
}[];

export type ApiParking = {
  id: string;
  name: string;
  address: ApiAddress;
  main_image: string;
  type: string;
  parking_spots_count: number;
  access_type: string;
};

export type IParking = {
  id: string;
  name: string;
  address: IAddress;
  mainImage: string;
  type: string;
  parkingSpotsCount: number;
  accessType: string;
};

export enum ParkingType {
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
  NO_SERVICE = 'NO_SERVICE',
}

export interface ParkingGalleryEntry {
  id: string;
  image: string;
}

export enum Owner {
  OWNER = 'OWNER',
  SPOT_OWNER = 'SPOT_OWNER',
  ZONE_OWNER = 'ZONE_OWNER',
}

export interface ApiParkingDetails {
  name: string;
  type: ParkingType;
  description?: string;
  owner: string;
  address: ApiAddress;
  latitude: number;
  longitude: number;
  main_image: string;
  pdf_files: ApiPdfDetails[];
  gallery: ParkingGalleryEntry[];
  access_type: Owner;
}

export interface IParkingDetails {
  name: string;
  type: ParkingType;
  description?: string;
  owner: string;
  address: IAddress;
  latitude: number;
  longitude: number;
  mainImage: string;
  pdfFiles: IPdfDetails[];
  gallery: ParkingGalleryEntry[];
  accessType: Owner;
}

export type ApiChargersType = {
  id: string;
  manufacturer: string;
  name: string;
  status: string;
  is_enabled: boolean;
  parking_name: string;
  address: ApiAddress;
};

export type IChargersType = {
  id: string;
  manufacturer: string;
  name: string;
  status: string;
  isEnabled: boolean;
  parkingName: string;
  address: IAddress;
};

interface ParkingSpots {
  parking_spot_number: string;
}

export interface ApiCreateParkingSpotRequest {
  owner_phone_number?: string;
  parking_spot_number: string;
  entry_devices: string[];
}

export interface ApiEditParkingSpotRequest {
  parking_spot_number?: string;
  description?: string;
}

export interface Connector {
  id: string;
  port: number;
  status: string;
}

export interface ApiCharger {
  id: string;
  name: string;
  parking_name: string;
  parking_spots: ParkingSpots[];
  serial_number: string;
  status: string;
  is_enabled: boolean;
  connectors: Connector[];
  specification: {
    manufacturer: string;
    model: string;
    image: string;
  };
}

export interface ICharger {
  id: string;
  name: string;
  parkingName: string;
  parkingSpots: ParkingSpots[];
  serialNumber: string;
  status: string;
  isEnabled: boolean;
  connectors: Connector[];
  specification: {
    manufacturer: string;
    model: string;
    image: string;
  };
}

export interface ApiPriceList {
  id: string;
  name: string;
  billing_method: string;
}

export interface ApiChargingRule {
  id: string;
  name: string;
  connectors: string[];
  groups: ApiRuleGroup[];
  price_list: string;
}

export interface IChargingRule {
  id: string;
  name: string;
  connectors: string[];
  groups: IRuleGroup[];
  priceList: string;
}

export interface ApiChargingRules {
  id: string;
  name: string;
  connectors: string[];
  groups: { name: string }[];
  price_list: string;
}

export interface IChargingRules {
  id: string;
  name: string;
  connectors: string[];
  groups: { name: string }[];
  priceList: string;
}

export interface ApiAddress {
  address_line_1?: string;
  address_line_2?: string;
  post_code?: string;
  city?: string;
  country?: Country;
}

export interface IAddress {
  addressLine1?: string;
  addressLine2?: string;
  postCode?: string;
  city?: string;
  country?: Country;
}

export interface ApiProfile {
  birth_date?: string;
  address?: ApiAddress;
  avatar: string;
}

export interface IProfile {
  birthDate?: string;
  address: IAddress;
  avatar: string;
}

export interface ApiUserRequest {
  profile: {
    birth_date?: string;
    address?: ApiAddress;
  };
  first_name: string;
  last_name: string;
  currency?: string;
}

export interface ApiUserResponse {
  id: string;
  profile: ApiProfile;
  first_name: string;
  last_name: string;
  phone_number?: string;
  email: string;
  is_active: true;
  date_joined: string;
  is_registered?: boolean;
  wallet: {
    amount: string;
    currency: string;
  };
  partner: boolean;
}

export interface IUser {
  id: string;
  profile: IProfile;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
  isActive: true;
  dateJoined: Date;
  isRegistered?: boolean;
  wallet: {
    amount: string;
    currency: string;
  };
  partner: boolean;
}

export interface ApiBasicUser {
  id: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string | null;
  avatar: string;
}

export interface IBasicUser {
  id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string | null;
  avatar: string;
}

export interface ApiPromotion {
  parking: string;
  price_list_name: string;
  price_list: string;
  qr_promo: string;
  qr_promo_interval: number;
  qr_ascii: string;
  qr_expiration_date?: string | null;
  split_payment: boolean;
  wallet_limit?: number | null;
  wallet_amount?: number | null;
}

export interface IPromotion {
  parking: string;
  priceListName: string;
  priceList: string;
  qrPromo: string;
  qrPromoInterval: number;
  qrAscii: string;
  qrExpirationDate?: Date | null;
  splitPayment: boolean;
  walletLimit?: number | null;
  walletAmount?: number | null;
}

export interface ApiEntryDevice {
  id: string;
  name: string;
  gate_type: EntryEnum;
  description: string;
  hierarchy_position: number;
  direction: EntryDirectionEnum;
  public_id: string;
  parking_zone: string;
  parking_spots: string[];
}

export interface IEntryDevice {
  id: string;
  name: string;
  gateType: EntryEnum;
  description: string;
  hierarchyPosition: number;
  direction: EntryDirectionEnum;
  publicId: string;
  parkingZone: string;
  parkingSpots: string[];
}

export interface ApiPartnerActivity {
  parking_spot_id?: string | null;
  parking_spot: string | null;
  license_plate?: string | null;
  entitlement_reason: string;
  start: string;
  end: string | null;
  event_start: string;
  event_end: string | null;
  total_price?: {
    price: string;
    currency: CurrencyTypes;
  };
}

export interface IPartnerActivity {
  parkingSpotId?: string | null;
  parkingSpot: string | null;
  licensePlate?: string | null;
  entitlementReason: string;
  start: string;
  end: string | null;
  eventStart: string;
  eventEnd: string | null;
  totalPrice?: {
    price: string;
    currency: CurrencyTypes;
  };
}

export interface ApiParkingPriceListDetails {
  id: string;
  user: string;
  name: string;
  description?: string;
  identifier?: string;
  currency: CurrencyTypes;
  price_per_first_period: number | null;
  price_per_second_period: number | null;
  price_per_third_period: number | null;
  periods: ApiPriceListPeriod[];
}

export interface ApiParkingPriceListRequest
  extends Omit<
    ApiParkingPriceListDetails,
    'id' | 'user' | 'price_per_first_period' | 'price_per_second_period' | 'price_per_third_period' | 'periods'
  > {
  periods?: ApiPriceListPeriodRequest[];
}

export interface IParkingPriceListDetails {
  id: string;
  user: string;
  name: string;
  description?: string;
  identifier?: string;
  currency: CurrencyTypes;
  pricePerFirstPeriod: number | null;
  pricePerSecondPeriod: number | null;
  pricePerThirdPeriod: number | null;
  periods: IPriceListPeriod[];
}

export interface ApiPriceListPeriod {
  id: string;
  price_list: string;
  duration?: number;
  time_unit?: TimeUnit;
  price: string;
  position: number;
  price_per?: PricePerType | null;
  minute_based_billing?: boolean;
  pricing_band?: number;
}

export type ApiPriceListPeriodRequest = Omit<ApiPriceListPeriod, 'id' | 'price_list' | 'position'>;

export interface IPriceListPeriod {
  id: string;
  priceList: string;
  duration?: number | null;
  timeUnit?: TimeUnit | null;
  price: string;
  position: number;
  pricePer?: PricePerType | null;
  minuteBasedBilling?: boolean;
  pricingBand?: number;
}

export const TimeUnits = {
  MINUTE: 1,
  HOUR: 60,
  DAY: 1440,
  MONTH: 43200,
};

export type TimeUnit = TypeFromConstObject<typeof TimeUnits>;

export const PricePerTypes = {
  PERIOD: 0,
  MINUTE: 1,
  HOUR: 60,
  DAY: 1440,
  MONTH: 43200,
};

export type PricePerType = TypeFromConstObject<typeof PricePerTypes>;

export interface ApiEvPriceListDetails {
  id: string;
  name: string;
  description?: string;
  billing_method?: BillingMethodEnum;
  price_per_kwh?: string | null;
  price_per_h?: string | null;
  backoff_time?: number | null;
  currency: string;
}

export type ApiEvPriceListRequest = Omit<ApiEvPriceListDetails, 'id'>;

export interface IEvPriceListDetails {
  id: string;
  name: string;
  description?: string;
  billingMethod?: BillingMethodEnum;
  pricePerKwh?: string | null;
  pricePerH?: string | null;
  backoffTime?: number | null;
  currency: string;
}

export enum BillingMethodEnum {
  PER_TIME = 'PER_TIME',
  PER_KWH = 'PER_KWH',
  MIXED = 'MIXED',
}

export interface ParkingSubscriptionTimeRestriction {
  weekday: Weekday;
  start: string;
  end: string;
}

export interface ApiSimpleParking {
  id: string;
  name: string;
  latitude: string;
  longitude: string;
  address: ApiAddress;
  main_image: string;
}

export interface ISimpleParking {
  id: string;
  name: string;
  latitude: string;
  longitude: string;
  address: IAddress;
  mainImage: string;
}

export interface SimpleParkingZone {
  id: string;
  name: string;
}

export interface ApiSubscriptionsPriceListRequest {
  name: string;
  description?: string;
  duration: string;
  price: string;
  currency: CurrencyTypes;
  requires_approval?: boolean;
  approval_email?: string | null;
  has_time_restrictions?: boolean;
  time_restrictions: ParkingSubscriptionTimeRestriction[];
}

export interface ApiSubscriptionsPriceListDetails extends ApiSubscriptionsPriceListRequest {
  id: string;
  parkings: ApiSimpleParking[];
  parking_zones: SimpleParkingZone[];
}

export interface ISubscriptionsPriceListDetails {
  id: string;
  name: string;
  parkings: ISimpleParking[];
  parkingZones: SimpleParkingZone[];
  description?: string;
  duration: string;
  price: string;
  currency: CurrencyTypes;
  requiresApproval?: boolean;
  approvalEmail?: string | null;
  hasTimeRestrictions?: boolean;
  timeRestrictions: ParkingSubscriptionTimeRestriction[];
}

export interface ApiEntryDevice {
  id: string;
  name: string;
  gate_type: EntryEnum;
  description: string;
  hierarchy_position: number;
  direction: EntryDirectionEnum;
  public_id: string;
  parking_spots: string[];
}

export interface IEntryDevice {
  id: string;
  name: string;
  gateType: EntryEnum;
  description: string;
  hierarchyPosition: number;
  direction: EntryDirectionEnum;
  publicId: string;
  parkingSpots: string[];
}

export enum EntryEnum {
  LIFT_GATE = 'LIFT_GATE',
  SWING_GATE = 'SWING_GATE',
  SLIDE_GATE = 'SLIDE_GATE',
  BARRIER = 'BARRIER',
  DOOR = 'DOOR',
}

export enum EntryDirectionEnum {
  ENTRY = 'ENTRY',
  EXIT = 'EXIT',
  IN_OUT = 'IN_OUT',
}

export interface ApiParkingSpot {
  id: string;
  parking: string;
  parking_zone?: string;
  parking_name: string;
  parking_spot_number: string;
  description?: string;
  owner?: {
    phone_number?: string;
    email: string;
  };
  co_users: string[];
  main_image: string;
  created_at: string;
  updated_at: string;
  first_pricing_period: number;
  second_pricing_period: number;
  third_pricing_period: number;
  price_per_first_period: string;
  price_per_second_period: string;
  price_per_third_period: string;
  currency: CurrencyTypes;
  pdf_files: ApiPdfDetails[];
}

export interface IParkingSpot {
  id: string;
  parking: string;
  parkingZone?: string;
  parkingName: string;
  parkingSpotNumber: string;
  description?: string;
  owner?: {
    phoneNumber?: string;
    email: string;
  };
  coUsers: string[];
  mainImage: string;
  createdAt: string;
  updatedAt: string;
  firstPricingPeriod: number;
  secondPricingPeriod: number;
  thirdPricingPeriod: number;
  pricePerFirstPeriod: string;
  pricePerSecondPeriod: string;
  pricePerThirdPeriod: string;
  currency: CurrencyTypes;
  pdfFiles: IPdfDetails[];
}

export interface ApiParkingSpotDetailsByParking {
  id: string;
  parking: string;
  parking_name: string;
  parking_zone?: string | null;
  parking_zone_name: string | null;
  parking_spot_number: string;
  created_at: string;
  updated_at: string;
  owner?: {
    phone_number?: string | null;
    email: string;
  } | null;
  has_images: boolean;
  has_co_users: boolean;
  has_pdfs: boolean;
  has_rules: boolean;
  permissions: {
    can_edit: boolean;
    can_delete: boolean;
  };
}

export interface IParkingSpotDetailsByParking {
  id: string;
  parking: string;
  parkingName: string;
  parkingZone?: string | null;
  parkingZoneName: string | null;
  parkingSpotNumber: string;
  createdAt: string;
  updatedAt: string;
  owner?: {
    phoneNumber?: string | null;
    email: string;
  } | null;
  hasImages: boolean;
  hasCoUsers: boolean;
  hasPdfs: boolean;
  hasRules: boolean;
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
}

export enum ReservationTypeEnum {
  NO_RESERVATIONS = 'NO_RESERVATIONS',
  HAS_RESERVATIONS = 'HAS_RESERVATIONS',
  MIXED_RESERVATIONS = 'MIXED_RESERVATIONS',
  NO_SERVICE = 'NO_SERVICE',
}

export interface ApiParkingZoneDetailsByParking {
  id: string;
  name: string;
  reservation_type: ReservationTypeEnum;
  spot_count: number;
}

export interface IParkingZoneDetailsByParking {
  id: string;
  name: string;
  reservationType: ReservationTypeEnum;
  spotCount: number;
}

export interface ApiSingleParkingZone {
  id: string;
  name: string;
  parking: string;
  parking_name: string;
  reservation_type: ReservationTypeEnum;
  description?: string;
  pdf_files: ApiPdfDetails[];
}

export interface ISingleParkingZone {
  id: string;
  name: string;
  parking: string;
  parkingName: string;
  reservationType: ReservationTypeEnum;
  description?: string;
  pdfFiles: IPdfDetails[];
}

export interface ApiPdfDetails {
  id: string;
  file_name: string;
  file: string;
}

export interface IPdfDetails {
  id: string;
  fileName: string;
  file: string;
}

export interface Availability {
  weekday: Weekday;
  start: string;
  end: string;
}

export enum ParkingZoneRuleTypeEnum {
  RESERVATIONS = 'RESERVATIONS',
  TICKETS = 'TICKETS',
}

export interface ApiParkingRuleRequest {
  name: string;
  price_list: string;
  parking_id: string;
  parking_zone_id?: string;
  parking_zone_rule_type?: ParkingZoneRuleTypeEnum;
  parking_spot_ids?: string[];
  groups?: string[];
  availabilities: Availability[];
  valid_from?: string | null;
  valid_to?: string | null;
}

export interface ApiEditParkingRuleRequest {
  name: string;
  price_list: string;
  parking_spot_ids?: string[];
  groups?: string[];
  availabilities: Availability[];
  valid_from?: string | null;
  valid_to?: string | null;
  apply_for_all_spots?: boolean;
}

export interface ApiParkingRulesItem {
  id: string;
  name: string;
  groups: {
    id: string;
    name: string;
  }[];
  price_list: string;
  parking_spots: string[];
  availabilities: Availability[];
  permissions: {
    can_edit: boolean;
    can_delete: boolean;
  };
}

export interface IParkingRulesItem {
  id: string;
  name: string;
  groups: {
    id: string;
    name: string;
  }[];
  priceList: string;
  parkingSpots: string[];
  availabilities: Availability[];
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
}

export interface ApiSingleZoneParkingRulesItem {
  id: string;
  name: string;
  groups: {
    id: string;
    name: string;
  }[];
  price_list: string;
  parking_spots: string[];
  availabilities: Availability[];
}

export interface ISingleZoneParkingRulesItem {
  id: string;
  name: string;
  groups: {
    id: string;
    name: string;
  }[];
  priceList: string;
  parkingSpots: string[];
  availabilities: Availability[];
}

export interface ApiSingleZoneParkingSubscriptionsItem {
  id: string;
  name: string;
  parkings: ApiParkingDetails[];
  parking_zones: {
    id: string;
    name: string;
  }[];
  description?: string;
  duration: string;
  price: string;
  currency: CurrencyTypes;
  requires_approval: boolean;
  approval_email: string;
  has_time_restrictions: boolean;
  time_restrictions: ParkingSubscriptionTimeRestriction[];
}

export interface ISingleZoneParkingSubscriptionsItem {
  id: string;
  name: string;
  parkings: IParkingDetails[];
  parkingZones: {
    id: string;
    name: string;
  }[];
  description?: string;
  duration: string;
  price: string;
  currency: CurrencyTypes;
  requiresApproval: boolean;
  approvalEmail: string;
  hasTimeRestrictions: boolean;
  timeRestrictions: ParkingSubscriptionTimeRestriction[];
}

export interface ApiParkingRuleDetails {
  id: string;
  name: string;
  groups: {
    id: string;
    name: string;
  }[];
  price_list: string;
  parking_zone: string;
  parking_spots: string[];
  availabilities: Availability[];
  valid_from: string;
  valid_to: string;
}

export interface IParkingRuleDetails {
  id: string;
  name: string;
  groups: {
    id: string;
    name: string;
  }[];
  priceList: string;
  parkingZone: string;
  parkingSpots: string[];
  availabilities: Availability[];
  validFrom: string;
  validTo: string;
}

export interface ApiParkingSpotRule {
  id: string;
  name: string;
  groups: {
    id: string;
    name: string;
  }[];
  price_list: string;
  availabilities: Availability[];
  parking_spots: {
    id: string;
    parking_spot_number: string;
  }[];
}

export interface IParkingSpotRule {
  id: string;
  name: string;
  groups: {
    id: string;
    name: string;
  }[];
  priceList: string;
  availabilities: Availability[];
  parkingSpots: {
    id: string;
    parkingSpotNumber: string;
  }[];
}

export interface ApiUserWallet {
  balance?: string;
  total_income?: string;
  transaction_count: number;
}

export interface IUserWallet {
  balance?: string;
  totalIncome?: string;
  transactionCount: number;
}

export interface ApiUserStatistics {
  charging_total?: string;
  date: string;
  parking_total?: string;
  total?: string;
}

export interface IUserStatistics {
  chargingTotal?: string;
  date: string;
  parkingTotal?: string;
  total?: string;
}

export interface IPaymentRequest {
  id: string;
  status: string;
  transaction_date: string;
  payment_display_text: string;
  payment_method: string;
  payment_wallet: string;
  card_number: string;
}

export interface ApiUserLatestTransactions {
  can_request_invoice: boolean;
  charging_consumed_watts: number;
  created_at: string;
  id: string;
  license_plate: string;
  parking_name: string;
  payment_request: IPaymentRequest | null;
  price: {
    currency: CurrencyTypes;
    price: number;
  };
  reason: string;
  receipt_available: boolean;
  receipt_requested: boolean;
  total_time: string;
  user_price: {
    currency: CurrencyTypes;
    price: string;
  };
}

export interface IUserLatestTransactions {
  canRequestInvoice: boolean;
  chargingConsumedWatts: number;
  createdAt: string;
  id: string;
  licensePlate: string;
  parkingName: string;
  paymentRequest: IPaymentRequest | null;
  price: {
    currency: CurrencyTypes;
    price: number;
  };
  reason: string;
  receiptAvailable: boolean;
  receiptRequested: boolean;
  totalTime: string;
  userPrice: {
    currency: CurrencyTypes;
    price: string;
  };
}

export interface ApiHistoryParking {
  license_plate: string;
  start?: string;
  end?: string;
  status: string;
  paid_total: {
    price: string;
    currency: string;
  };
  parking_name: string;
  parking_spot_number: string;
  history_type: string;
}

export interface IHistoryParking {
  id: number;
  licensePlate: string;
  start?: string;
  end?: string;
  status: string;
  paidTotal: {
    price: string;
    currency: string;
  };
  parkingName: string;
  parkingSpotNumber: string;
  historyType: string;
}

export interface ApiHistoryCharging {
  license_plate: string;
  start?: string;
  end?: string;
  status: string;
  paid_total: {
    price: string;
    currency: string;
  };
  charge_point_name: string;
  charge_point_port: number;
  parking_name: string;
  charging_consumed_watts: string;
}

export interface IHistoryCharging {
  id: number;
  licensePlate: string;
  start?: string;
  end?: string;
  status: string;
  paidTotal: {
    price: string;
    currency: string;
  };
  chargePointName: string;
  chargePointPort: number;
  parkingName: string;
  chargingConsumedWatts: string;
}

export enum ParkingSpotPermission {
  ParkingSpotView = 'view_parkingspot',
  ParkingSpotFreeAccess = 'free_access_parkingspot',
  ParkingSpotShare = 'share_parkingspot',
  AssignPermission = 'assign_permissions_parkingspot',
}

export type ParkingSpotPermissionsState = Record<ParkingSpotPermission, boolean>;

export interface ApiCoUser {
  id?: number;
  phone_number?: string;
  group_id?: string;
  group_name: string;
  permissions: ParkingSpotPermission[];
  is_group?: boolean;
  first_name?: string;
  last_name?: string;
  number_of_users?: number;
}

export interface ICoUser {
  id?: number;
  phoneNumber?: string;
  groupId?: string;
  groupName?: string;
  permissions: ParkingSpotPermission[];
  isGroup?: boolean;
  firstName?: string;
  numberOfUsers?: number;
  lastName?: string;
}

export enum ParkingCalendarEntitlementReason {
  ParkingSubscription = 'PARKING_SUBSCRIPTION',
  ParkingReservation = 'RESERVATION',
}

export type ParkingCalendarState = Record<ParkingCalendarEntitlementReason, string>;
export interface ApiParkingCalendar {
  id: string;
  event_id: string;
  start: string;
  end: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  };
  parking_spot_id: string;
  parking_spot_name: string;
  license_plate: string;
  entitlement_reason: ParkingCalendarEntitlementReason;
  event_start: string;
  event_end: string;
}

export interface IParkingCalendar {
  id: string;
  eventID: string;
  start: string;
  end: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  parkingSpotId: string;
  parkingSpotName: string;
  resource: string;
  licensePlate: string;
  entitlementReason: string;
  color: string;
  colorClass: string;
  eventStart: string;
  eventEnd: string;
  date?: Date;
}

export interface ApiShareParkingSpot {
  phone_number: string;
  parking_spot: string;
  start: Dayjs;
  end: Dayjs;
  has_time_restrictions: boolean;
  time_restrictions: Availability[];
}
