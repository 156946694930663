export const api = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  endpoints: {
    LOGIN: '/auth/login/',
    REFRESH: '/auth/refresh/',
    REQUEST_PASSWORD_RESET: '/auth/password-reset-request/',
    VERIFY_PASSWORD_RESET: '/auth/password-reset-verify/',
    RESET_PASSWORD: '/auth/password-reset/',

    USER_PARKINGS: '/parkings/',
    SINGLE_PARKING: ({ id }: { id: string }) => `/parkings/${id}/`,
    SINGLE_PARKING_MAIN_IMAGE: ({ id }: { id: string }) => `/parkings/${id}/main-image/`,
    SINGLE_PARKING_GALLERY: ({ id }: { id: string }) => `/parkings/${id}/gallery/`,
    SINGLE_PARKING_GALLERY_DELETE: ({ id, galleryEntryId }: { id: string; galleryEntryId: string }) =>
      `/parkings/${id}/gallery/${galleryEntryId}/`,
    SINGLE_PARKING_PDFS: ({ id }: { id: string }) => `/parkings/${id}/pdfs/`,
    PARKING_ENTRY_DEVICE_OPEN: ({ parkingId, entryDeviceId }: { parkingId: string; entryDeviceId: string }) =>
      `/parkings/${parkingId}/entry-devices/${entryDeviceId}/open/`,
    PARKING_SPOTS: ({ id }: { id: string }) => `/parkings/${id}/spots/`,
    PARKING_ZONES: ({ id }: { id: string }) => `/parkings/${id}/zones/`,
    PARKING_RULES: ({ id }: { id: string }) => `/parkings/${id}/rules/`,
    PARKING_CALENDAR: ({
      id,
      start,
      end,
      parkingSpotId,
      parkingZoneId,
    }: {
      id: string;
      start: string;
      end: string;
      parkingSpotId: string;
      parkingZoneId: string;
    }) =>
      `/parkings/${id}/calendar/?start=${start}&end=${end}&parking_spot_id=${parkingSpotId}&zone_id=${parkingZoneId}`,
    DELETE_PARKING_SPOT_RESERVATION: ({ reservationId, parkingId }: { reservationId: string; parkingId: string }) =>
      `/parkings/${parkingId}/calendar/reservations/${reservationId}/`,
    DELETE_PARKING_SPOT_SUBSCRIPTION: ({ subscriptionId, parkingId }: { subscriptionId: string; parkingId: string }) =>
      `/parkings/${parkingId}/calendar/subscriptions/${subscriptionId}/`,
    PARKING_SHARE_SPOT: ({ id }: { id: string }) => `/parkings/${id}/calendar/share-spot/`,
    PARKING_RULE: ({ parkingId, ruleId }: { parkingId: string; ruleId: string }) =>
      `/parkings/${parkingId}/rules/${ruleId}/`,
    SINGLE_PARKING_SPOT: ({ id }: { id: string }) => `/parking-spots/${id}/`,
    SINGLE_PARKING_SPOT_COUSERS: ({ id }: { id: string }) => `/parking-spots/${id}/co-users/`,
    REMOVE_PAKING_SPOT_COUSER: ({ id }: { id: string }) => `/parking-spots/${id}/co-users/remove`,
    SINGLE_PARKING_SPOT_RULES: ({ id }: { id: string }) => `/parking-spots/${id}/rules/`,
    ASSING_PARKING_SPOT_RULE: ({ ruleId }: { ruleId: string }) => `/rules/${ruleId}/assign/`,
    REMOVE_RULE_FROM_SPOT: ({ spotId, ruleId }: { spotId: string; ruleId: string }) =>
      `/parking-spots/${spotId}/rules/${ruleId}/`,
    PARKING_ENTRY_DEVICES: ({ id }: { id: string }) => `/parkings/${id}/entry-devices/`,
    PARKING_SPOT_MAIN_IMAGE: ({ spotId }: { spotId: string }) => `/parking-spots/${spotId}/main-image/`,
    PARKING_SPOT_PDFS: ({ spotId }: { spotId: string }) => `/parking-spots/${spotId}/pdfs/`,
    SINGLE_PARKING_ZONE: ({ id }: { id: string }) => `/parking-zones/${id}/`,
    SINGLE_PARKING_ZONE_PDFS: ({ id }: { id: string }) => `/parking-zones/${id}/pdfs/`,
    SINGLE_PARKING_ZONE_RULES: ({ id }: { id: string }) => `/parking-zones/${id}/rules/`,
    SINGLE_PARKING_ZONE_SPOTS: ({ id }: { id: string }) => `/parking-zones/${id}/spots/`,
    SINGLE_PARKING_ZONE_SUBSCRIPTIONS: ({ id }: { id: string }) => `/parking-zones/${id}/subscriptions/`,
    ZONES_SUBSCRIPTIONS: ({ id }: { id: string }) => `/subscriptions/${id}/zones/`,

    USER_GROUPS: '/groups/',
    SINGLE_GROUP: ({ id }: { id: string }) => `/groups/${id}/`,
    VALIDATE_USER_PHONE: '/groups/users/validate/',
    SINGLE_GROUP_USERS: ({ groupId }: { groupId: string }) => `/groups/${groupId}/users/`,
    DELETE_GROUP_USER: ({ groupId }: { groupId: string }) => `/groups/${groupId}/users/remove/`,

    USER_DETAILS: '/user-details/',
    USER_DETAILS_WALLET: ({ period, type }: { period?: string; type?: string }) =>
      `/user-details/wallet/?period=${period}&type=${type}`,
    USER_DETAILS_STATISTICS: ({ period, type }: { period?: string; type?: string }) =>
      `/user-details/wallet/statistics/?period=${period}&type=${type}`,
    USER_DETAILS_TRANSACTIONS_LATEST: '/user-details/wallet/transactions/latest/',
    UPLOAD_AVATAR: '/user-details/avatar/',

    PARKING_PRICE_LISTS: '/price-lists/',
    EV_PRICE_LISTS: '/ev-price-lists/',
    SUBSCRIPTION_PRICE_LISTS: '/subscriptions/',

    SINGLE_EV_PRICE_LIST: (id: string) => `/ev-price-lists/${id}/`,
    SINGLE_PARKING_PRICE_LIST: (id: string) => `/price-lists/${id}/`,
    SINGLE_SUBSCRIPTION_PRICE_LIST: (id: string) => `/subscriptions/${id}/`,
    USER_CHARGERS: `/chargers/`,
    SINGLE_CHARGER: {
      TURN_ON_OFF: (chargerId: string) => `/chargers/${chargerId}/change-availability/`,
      DATA: (chargerId: string) => `/chargers/${chargerId}/`,
      CONNECTOR_ON_OFF: (connectorId: string) => `/chargers/connectors/${connectorId}/change-availability/`,
      RULES: ({ id }: { id: string }) => `/chargers/${id}/rules/`,
      CHARGING_START: (connectorId: string) => `/chargers/connectors/${connectorId}/owner-start-charging/`,
      CHARGING_STOP: (connectorId: string) => `/chargers/connectors/${connectorId}/owner-stop-charging/`,
    },

    CHARGING_RULES: ({ id }: { id: string }) => `/chargers/${id}/rules/`,
    CHARGING_RULE: ({ id, ruleId }: { id: string; ruleId: string }) => `/chargers/${id}/rules/${ruleId}/`,

    DELETE_PDF: ({ pdfEntryId }: { pdfEntryId: string }) => `/pdfs/${pdfEntryId}/`,

    HISTORY: {
      PARKING: 'user-details/history/parking/',
      CHARGING: 'user-details/history/charging/',
    },

    PARTNER: {
      PROMOTION_PRICE_LISTS: '/partners/promotion-price-lists/',
      ACTIVITIES: '/partners/parking-activity/',
      DEVICES: '/partners/parking-devices/',
      OPEN_ENTRY_DEVICE: ({ id }: { id: string }) => `/partners/parking-devices/${id}/open/`,
    },
  },
};
