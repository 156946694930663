import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BackButton from '../../../components/common/buttons/BackButton';
import TitleWrapper from '../../../components/wrappers/titleWrapper/TitleWrapper';
import useAddParkingSpot from '../../../hooks/api/parkings/useAddParkingSpot';
import useSingleParking from '../../../hooks/api/parkings/useSingleParking';
import { useIsTablet } from '../../../hooks/devices/useIsTablet';
import { routes } from '../../../static/routes';
import { ApiCreateParkingSpotRequest } from '../../../types/api/ApiTypes';
import AddParkingSpotForm from './components/AddParkingSpotForm';

const AddParkingSpot = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();
  const { parkingId } = useParams();

  const [searchParams] = useSearchParams();
  const zoneId = searchParams.get('zoneId');

  const { parking } = useSingleParking({ parkingId: parkingId as string });
  const { addSpot, isLoading } = useAddParkingSpot();

  const handleAddSpot = async (data: ApiCreateParkingSpotRequest) => {
    const reqBody = {
      parkingId: parkingId as string,
      spot: data,
      ...(!!zoneId && { parking_zone_id: zoneId }),
    };

    console.log('reqBody', reqBody);
    const res = await addSpot(reqBody);
    const responseData: { id: string } = res.data;

    navigate(routes.editParkingSpot(responseData.id));
  };

  const modifiedBreadcrumbs =
    !!parkingId && !!parking?.name ? [{ path: parkingId as string, text: parking?.name as string }] : [];

  return (
    <TitleWrapper
      title={`${t('addParkingSpot.title')}${!!parking?.name && ` (${parking.name})`}`}
      breadcrumbsProps={{ limit: 3, modifiedBreadcrumbs }}
    >
      {!isTablet && <BackButton className="mt-8 mb-16 " />}
      {!!parking && (
        <AddParkingSpotForm
          onSubmitForm={handleAddSpot}
          isSubmitBtnLoading={isLoading}
          parkingId={parkingId as string}
          submitBtnText={t('addParkingSpot.form.submitBtn')}
        />
      )}
    </TitleWrapper>
  );
};

export default AddParkingSpot;
