import { Controller, FieldArrayWithId, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import {
  TimeInterval,
  TimeIntervalsModal,
  TimeIntervalsModalSaveProps,
} from '../../../../../components/common/modals/TimeIntervalsModal';
import { DropdownOption } from '../../../../../types/Dropdown.types';
import { ComponentState, ComponentStates } from '../../../../../types/ComponentStates.types';
import { Weekdays } from '../../../../../types/weekdays/Weekdays';
import { useTranslation } from 'react-i18next';
import useParkingSpotsDropdownOptions from '../../../../../hooks/dopdownOptions/useParkingSpotsDropdownOptions';
import { LabeledStateDropdown } from '../../../../../components/common/dropdowns/LabeledStateDropdown';
import FormSectionTitle from '../../../../../components/common/formSectionTitle/FormSectionTitle';
import { LabeledStateInput } from '../../../../../components/common/inputs/LabeledStateInput';
import XMarkButton from '../../../../../components/common/buttons/XMarkButton';
import { LabeledStateDatetimepicker } from '../../../../../components/common/datetimepicker/LabeledDatetimePicker';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { SecondaryButton } from '../../../../../components/common/buttons/SecondaryButton';
import { FormControlLabel } from '@mui/material';
import Switch from '../../../../../components/common/switch/Switch';
import dayjs from 'dayjs';
import EditIcon from '../../../../../components/icons/EditIcon';
import { useState } from 'react';
import { isStartOfDay } from '../../../../../utils/dates/isStartOfDay/isStartOfDay';
import { isEndOfDay } from '../../../../../utils/dates/isEndOfDay/isEndOfDay';
import { yupResolver } from '@hookform/resolvers/yup';
import { shareParkingSpotSchema } from '../../../../../static/validationSchema/parkings/shareParkingSpotSchema';
import { ApiShareParkingSpot } from '../../../../../types/api/ApiTypes';
import { getTimeRestrictionsFromTimeIntervals } from '../../../../../utils/priceLists/getTimeRestrictionsFromTimeIntervals/getTimeRestrictionsFromTimeIntervals';
import { getFullAvailability } from '../../../../../utils/parkingRules/getFullAvailability/getFullAvailability';

interface IFormInputs {
  parkingSpot: DropdownOption;
  phoneNumber: string;
  timeIntervals: TimeInterval[];
  validFrom: Date | null;
  validTo: Date | null;
}

type InputsKeys = keyof IFormInputs;

interface ShareSpotFormProps {
  parkingId: string;
  isSubmitBtnLoading: boolean;
  onSubmitForm: (data: ApiShareParkingSpot) => Promise<void>;
}

const ShareSpotForm = ({ parkingId, isSubmitBtnLoading, onSubmitForm }: ShareSpotFormProps) => {
  const { t } = useTranslation();
  const [isSpotTimeRestricted, setIsSpotTimeRestricted] = useState(false);
  const [isModalOpenIdx, setIsModalOpenIdx] = useState<number | null>(null);

  const { spotsDropdownOptions } = useParkingSpotsDropdownOptions({ parkingId });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<IFormInputs>({
    mode: 'onSubmit',
    resolver: yupResolver(shareParkingSpotSchema()),
    defaultValues: {
      parkingSpot: undefined,
      phoneNumber: '',
      timeIntervals: Object.values(Weekdays).map(item => ({
        time: 'unavailable',
        weekday: item,
      })),
      validFrom: null,
      validTo: null,
    },
  });
  const getInputState = (fieldName: InputsKeys): ComponentState => {
    if (errors[fieldName] !== undefined) {
      return ComponentStates.ERROR;
    }

    return ComponentStates.DEFAULT;
  };

  const { fields: timeIntervalsFields, update } = useFieldArray({
    control,
    name: 'timeIntervals',
  });

  const onSubmit: SubmitHandler<IFormInputs> = async data => {
    const newData: ApiShareParkingSpot = {
      parking_spot: data.parkingSpot.id,
      phone_number: data.phoneNumber,
      time_restrictions: isSpotTimeRestricted
        ? getTimeRestrictionsFromTimeIntervals(timeIntervalsFields)
        : getFullAvailability(),
      has_time_restrictions: isSpotTimeRestricted,
      start: dayjs(data.validFrom),
      end: dayjs(data.validTo),
    };
    await onSubmitForm(newData);
  };
  const getDisplayTime = (field: FieldArrayWithId<IFormInputs, 'timeIntervals', 'id'>) => {
    if (field.time === 'unavailable') {
      return <p>{t(`priceListForm.subscriptionForm.times.unavailable`)}</p>;
    }

    const isFullDay = field.time.length === 1 && isStartOfDay(field.time?.[0]?.from) && isEndOfDay(field.time?.[0]?.to);

    if (isFullDay) {
      return <p>{t(`priceListForm.subscriptionForm.times.full-day`)}</p>;
    }

    if (field.time.length !== 0) {
      return (
        <>
          {field.time?.map(timeItem => {
            const key = `${timeItem.from.toString()}-${timeItem.to.toString()}`;

            return <p key={key}>{`${dayjs(timeItem.from).format('HH:mm')} - ${dayjs(timeItem.to).format('HH:mm')}`}</p>;
          })}
        </>
      );
    }

    return <></>;
  };

  const submitBtnIcon = <PlusIcon height={16} />;
  const submitBtnText = t('parkingSpotCalendar.form.shareBtn');

  return (
    <div className="flex justify-between gap-16 pb-8 grow">
      <div className="w-full max-w-5xl grow pb-52">
        <div className="pb-12 border-b lg:pb-16 border-grey300 mt-16">
          <FormSectionTitle number={1} title={t('parkingSpotCalendar.form.spot')} />
          <div className="lg:pl-8">
            <div className="max-w-xl mt-6 ">
              <Controller
                name="parkingSpot"
                control={control}
                render={({ field }) => (
                  <LabeledStateDropdown
                    id="parking-spots"
                    state={getInputState('parkingSpot')}
                    labeledProps={{
                      wrapperClassName: 'w-full',
                      errorMessage: t(`errorMessages.${errors.parkingSpot?.message}`),
                    }}
                    dropdownProps={{
                      placeholder: t('parkingRuleForm.appliesTo.dropdownPlaceholder'),
                      buttonClassName: 'w-full flex justify-between items-center',
                      options: spotsDropdownOptions ?? [],
                      currentOption: field.value,
                      setNewOption: field.onChange,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="pb-12 border-b lg:pb-16 border-grey300 mt-16">
          <FormSectionTitle number={2} title={t('parkingSpotCalendar.form.user')} />

          <div className="lg:pl-8">
            <div className="max-w-xl mt-6 ">
              <LabeledStateInput
                id="phoneNumber"
                state={getInputState('phoneNumber')}
                labeledProps={{
                  wrapperClassName: 'mb-2 mt-6 max-w-xl',
                  label: t('editParkingSpot.cousers.add.inputLabel'),
                  errorMessage: t(`errorMessages.${errors.phoneNumber?.message}`),
                }}
                inputProps={{
                  register: {
                    ...register('phoneNumber'),
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="pb-12 border-b lg:pb-16 border-grey300 mt-16">
          <FormSectionTitle number={3} title={t('parkingSpotCalendar.form.duration')} />

          <div className="lg:pl-8">
            <div className="max-w-xl mt-6 ">
              <div className="flex gap-4 justify-between max-sm:flex-wrap">
                <div className="flex gap-2">
                  <Controller
                    control={control}
                    name="validFrom"
                    render={({ field }) => (
                      <LabeledStateDatetimepicker
                        id="valid-from"
                        state={getInputState('validFrom')}
                        labeledProps={{
                          wrapperClassName: 'mb-4 max-w-md',
                          label: t('parkingRuleForm.ruleTimeRestrictions.from'),
                          errorMessage: t(`errorMessages.${errors.validFrom?.message}`),
                        }}
                        datetimepickerProps={{
                          date: field.value,
                          setDate: field.onChange,
                        }}
                      />
                    )}
                  />
                  <XMarkButton onClick={() => setValue('validFrom', null)} className="mt-5" />
                </div>

                <div className="flex gap-2">
                  <Controller
                    control={control}
                    name="validTo"
                    render={({ field }) => (
                      <LabeledStateDatetimepicker
                        id="valid-to"
                        state={getInputState('validTo')}
                        labeledProps={{
                          wrapperClassName: 'mb-4 max-w-md',
                          label: t('parkingRuleForm.ruleTimeRestrictions.to'),
                          errorMessage: t(`errorMessages.${errors.validTo?.message}`),
                        }}
                        datetimepickerProps={{
                          date: field.value,
                          setDate: field.onChange,
                        }}
                      />
                    )}
                  />
                  <XMarkButton onClick={() => setValue('validTo', null)} className="mt-5" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pb-12 mt-16 border-b lg:pb-16 border-grey300">
          <FormSectionTitle number={4} title={t('parkingSpotCalendar.form.availabilityRestrictions')} />
          <div className="flex flex-col lg:flex-row max-w-xl gap-4 mt-6 mb-12 lg:ml-8">
            <SecondaryButton
              markActive={!isSpotTimeRestricted}
              onClick={() => {
                setIsSpotTimeRestricted(false);
              }}
              prefixIcon={!isSpotTimeRestricted && <CheckIcon width={16} />}
            >
              {t('parkingSpotCalendar.form.noRestrictions')}
            </SecondaryButton>
            <SecondaryButton
              markActive={isSpotTimeRestricted}
              onClick={() => {
                setIsSpotTimeRestricted(true);
              }}
              prefixIcon={isSpotTimeRestricted && <CheckIcon width={16} />}
            >
              {t('parkingSpotCalendar.form.availableIn')}
            </SecondaryButton>
          </div>

          {isSpotTimeRestricted && (
            <>
              {timeIntervalsFields.map((field, idx) => (
                <div
                  key={idx}
                  className={`py-6 ml-8 ${
                    idx === timeIntervalsFields.length - 1 ? '' : 'border-b'
                  } border-grey300 max-w-xl`}
                >
                  <div className="flex justify-between items-center">
                    <FormControlLabel
                      control={
                        <Switch
                          className="mr-4"
                          isChecked={field.time !== 'unavailable'}
                          onChange={() =>
                            update(idx, {
                              time:
                                field.time !== 'unavailable'
                                  ? 'unavailable'
                                  : [
                                      {
                                        from: dayjs().startOf('day').toDate(),
                                        to: dayjs().endOf('day').toDate(),
                                      },
                                    ],
                              weekday: field.weekday,
                            })
                          }
                        />
                      }
                      label={t(`weekdays.${field.weekday}`)}
                    />
                    <div className="flex justify-between items-center w-[30%]">
                      <div className="flex flex-col">{getDisplayTime(field)}</div>
                      {field.time !== 'unavailable' && (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setIsModalOpenIdx(idx);
                          }}
                        >
                          <EditIcon />
                        </div>
                      )}
                    </div>

                    <TimeIntervalsModal
                      title={t('parkingRuleForm.spotTimeRestrictions.modal.title')}
                      description={t('parkingRuleForm.spotTimeRestrictions.modal.description')}
                      subtitle={t('parkingRuleForm.spotTimeRestrictions.modal.subtitle')}
                      onSave={(props: TimeIntervalsModalSaveProps) => {
                        if (props.useForAll) {
                          timeIntervalsFields.forEach((item, idx) => {
                            update(idx, {
                              time: props.addedIntervals,
                              weekday: item.weekday,
                            });
                          });
                        } else {
                          update(idx, {
                            time: props.addedIntervals,
                            weekday: field.weekday,
                          });
                        }
                      }}
                      isOpen={isModalOpenIdx === idx}
                      setIsOpen={setIsModalOpenIdx}
                      field={field}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <PrimaryButton
        disabled={isSubmitBtnLoading}
        onClick={handleSubmit(onSubmit)}
        className="w-fit h-fit"
        prefixIcon={submitBtnIcon}
      >
        {submitBtnText}
      </PrimaryButton>
    </div>
  );
};

export default ShareSpotForm;
