import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useTranslation } from 'react-i18next';
import useAxiosInstance from '../../../../hooks/axios/useAxiosInstance';
import React from 'react';
import { parkingSpotOption } from '../../../../types/parkings/parkingSpotOption';
import { ApiParkingCalendar, IParkingCalendar, ParkingCalendarEntitlementReason } from '../../../../types/api/ApiTypes';
import useParkingSpotsCalendarDropdownOptions from '../../../../hooks/dopdownOptions/useParkingSpotsCalendarDropDownOptions';
import useDeleteParkingSpotReservation from '../../../../hooks/api/parkings/useDeleteParkingSpotReservation';
import useDeleteParkingSpotSubscription from '../../../../hooks/api/parkings/useDeleteParkingSpotSubscription';
import { parkingSpotCalendarOptions } from '../../../../types/parkings/parkingSpotCalendarOptions';
import {
  Eventcalendar,
  locale,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscPageLoadingEvent,
  MbscResource,
} from '@mobiscroll/react';
import CalendarEvent from '../../parkingBasic/activities/components/CalendarEvent';
import CalendarHeader from '../../parkingBasic/activities/components/CalendarHeader';
import CalendarResource from '../../parkingBasic/activities/components/CalendarResource';
import { api } from '../../../../static/api';
import dayjs from 'dayjs';
import { transformParkingCalendar } from '../../../../utils/dataTransformers/transformParkingCalendar/transformParkingCalendar';

import Popup from '../../../../components/common/modals/Popup';
import { SecondaryButton } from '../../../../components/common/buttons/SecondaryButton';

interface CalendarProps {
  parkingId: string;
  parkingZoneId?: string;
}

const Calendar = ({ parkingId, parkingZoneId }: CalendarProps) => {
  const { i18n, t } = useTranslation();
  const axiosInstance = useAxiosInstance();
  const currentLocale = i18n.resolvedLanguage ?? 'pl';

  const [spotOption, setSpotOption] = React.useState<string>(parkingSpotOption.ALL);
  const [isOpen, setOpen] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState<IParkingCalendar | null>(null);
  const { spotsDropdownOptions, isLoading } = useParkingSpotsCalendarDropdownOptions({ parkingId, parkingZoneId });
  const { deleteParkingSpotReservation } = useDeleteParkingSpotReservation();
  const { deleteParkingSpotSubscription } = useDeleteParkingSpotSubscription();

  const [selectedView, setView] = React.useState<string>(parkingSpotCalendarOptions.CALENDAR);
  const [events, setEvents] = React.useState<IParkingCalendar[]>([]);
  const [resource, setResource] = React.useState<{ text: string; value: string; id: string }[]>([]);
  const view = React.useMemo<MbscEventcalendarView>(
    () =>
      selectedView === parkingSpotCalendarOptions.CALENDAR
        ? {
            calendar: { popover: true, count: true },
          }
        : {
            timeline: {
              type: parkingSpotCalendarOptions.DAY,
              currentTimeIndicator: true,
            },
          },
    [selectedView],
  );

  React.useEffect(() => {
    if (!isLoading) {
      setResource(
        spotsDropdownOptions?.filter(spot => {
          return spot.id !== parkingSpotOption.ALL;
        }) ?? [],
      );
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (spotOption === parkingSpotOption.ALL) {
      setResource(
        spotsDropdownOptions?.filter(spot => {
          return spot.id !== parkingSpotOption.ALL;
        }),
      );
    } else {
      setResource(
        spotsDropdownOptions?.filter(spot => {
          return spot.id === spotOption;
        }),
      );
    }
  }, [spotOption]);

  const viewChange = React.useCallback(({ value }: { value: React.SetStateAction<string> }) => {
    setView(value);
  }, []);

  const spotChange = React.useCallback(
    ({ value }: { value: React.SetStateAction<string> }) => {
      setSpotOption(value);
    },
    [resource],
  );

  const cancelBtnOnClick = async () => {
    if (
      selectedEvent?.eventID &&
      selectedEvent.entitlementReason === ParkingCalendarEntitlementReason.ParkingReservation
    ) {
      await deleteParkingSpotReservation({ reservationId: selectedEvent.eventID, parkingId });
      setOpen(false);
    }
    if (
      selectedEvent?.eventID &&
      selectedEvent.entitlementReason === ParkingCalendarEntitlementReason.ParkingSubscription
    ) {
      await deleteParkingSpotSubscription({ subscriptionId: selectedEvent.eventID, parkingId });
      setOpen(false);
    }
    setEvents(events.filter(event => event.id !== selectedEvent?.id));
  };
  const renderEvent = React.useCallback<(data: MbscCalendarEventData) => unknown>((data: MbscCalendarEventData) => {
    const original = data.original!;
    return (
      <CalendarEvent
        colorClass={original.colorClass}
        parkingSpotName={original.parkingSpotName}
        start={data.start}
        end={data.end}
      />
    );
  }, []);

  const renderHeader = () => {
    return (
      <CalendarHeader
        viewChange={viewChange}
        spotChange={spotChange}
        spotsDropdownOptions={spotsDropdownOptions}
        spotOption={spotOption}
        selectedView={selectedView}
      />
    );
  };
  const renderMyResource = (resource: MbscResource) => {
    return <CalendarResource text={resource.text} />;
  };

  const handleOnEventClick = React.useCallback((args: MbscEventClickEvent) => {
    const event = args.event;
    setSelectedEvent({ ...event, date: args.date });
    setOpen(true);
  }, []);

  const onPageLoading = React.useCallback(
    (event: MbscPageLoadingEvent) => {
      setEvents([]);
      axiosInstance
        .get(
          api.endpoints.PARKING_CALENDAR({
            id: parkingId,
            start: dayjs(event.firstDay).format('YYYY-MM-DD'),
            end: dayjs(event.lastDay).format('YYYY-MM-DD'),
            parkingSpotId: spotOption === parkingSpotOption.ALL ? '' : spotOption,
            parkingZoneId: parkingZoneId || '',
          }),
        )
        .then(({ data }) => {
          const apiParkingCalendar: ApiParkingCalendar[] | undefined = data;
          const parkingCalendar: IParkingCalendar[] | undefined = !!apiParkingCalendar
            ? apiParkingCalendar?.map(calendar => transformParkingCalendar(calendar))
            : undefined;
          setEvents(parkingCalendar ?? []);
        });
    },
    [spotOption],
  );

  return (
    <React.Fragment key={`${selectedView}-${spotOption}-${resource?.[0]?.id ?? 'all'}`}>
      <Eventcalendar
        renderEvent={renderEvent}
        renderHeader={renderHeader}
        renderResource={renderMyResource}
        theme="material"
        themeVariant="light"
        onEventClick={handleOnEventClick}
        onPageLoading={onPageLoading}
        clickToCreate={false}
        dragToCreate={false}
        dragToMove={false}
        dragToResize={false}
        locale={locale[currentLocale]}
        eventDelete={false}
        data={events}
        resources={resource}
        view={view}
      />
      <Popup isOpen={isOpen} setIsOpen={setOpen}>
        <h5 className="text-black700 text-base font-semibold">{dayjs(selectedEvent?.date).format('DD.MM.YYYY')}</h5>
        <div className="inline-flex items-baseline text-xs gap-4 mb-3">
          <div
            className={`relative grid select-none items-center whitespace-nowrap rounded-full ${selectedEvent?.colorClass} py-1 px-2 uppercase text-white`}
          >
            <span>{selectedEvent?.parkingSpotName}</span>
          </div>
          <span className="text-black500">
            {selectedEvent?.start
              ? `${t('parkingSpotCalendar.from')} ${dayjs(selectedEvent?.start).format('HH:mm')}`
              : ''}{' '}
            {selectedEvent?.end ? `${t('parkingSpotCalendar.to')} ${dayjs(selectedEvent?.end).format('HH:mm')}` : ''}
            {!selectedEvent?.start && !selectedEvent?.end && t('parkingSpotCalendar.allDay')}
          </span>
        </div>
        <div className="mb-3">
          <h5 className="text-black700 text-base font-semibold">
            {selectedEvent?.user?.firstName} {selectedEvent?.user?.lastName}
          </h5>
          <span className="text-xs">{selectedEvent?.user?.phoneNumber}</span>
        </div>
        <div className="mb-3">
          <h5 className="text-black700 text-base font-semibold">{t('parkingSpotCalendar.licensePlate')}:</h5>
          <span className="text-xs mb-3">{selectedEvent?.licensePlate ? selectedEvent?.licensePlate : '-'}</span>
        </div>

        <SecondaryButton onClick={cancelBtnOnClick}>{t('parkingSpotCalendar.cancelBtn')}</SecondaryButton>
      </Popup>
    </React.Fragment>
  );
};

export default Calendar;
