export enum QueryAliases {
  USER_PARKINGS = 'user-parkings',
  SINGLE_PARKING = 'single-parking',
  USER_GROUPS = 'user-groups',
  GROUP_DETAILS = 'group-details',
  USER_CHARGERS = 'user-chargers',
  USER_SINGLE_CHARGER = 'user-single-charger',
  USER_SINGLE_CHARGER_RULES = 'user-single-charger-rules',
  USER_SINGLE_CHARGER_START_STOP = 'user-single-charger-start-stop',
  CHARGER_DETAILS = 'charger-details',
  RULE_DETAILS = 'rule',
  USER_DETAILS = 'user-details',
  USER_DETAILS_STATISTICS = 'user-details-statistics',
  USER_DETAILS_TRANSACTIONS_LATEST = 'user-details-transactions-latest',
  USER_DETAILS_WALLET = 'user-details-wallet',
  PARKING_SPOTS = 'parking-spots',
  SINGLE_PARKING_SPOT = 'single-parking-spot',
  SINGLE_PARKING_SPOT_COUSERS = 'single-parking-spot-cousers',
  SINGLE_PARKING_SPOT_RULES = 'single-parking-spot-rules',
  PARKING_ZONES = 'parking-zones',
  SINGLE_PARKING_ZONES = 'single-parking-zones',
  SINGLE_PARKING_ZONE_RULES = 'single-parking-zone-rules',
  SINGLE_PARKING_ZONE_SPOTS = 'single-parking-zone-spots',
  SINGLE_PARKING_ZONE_SUBSCRIPTIONS = 'single-parking-zone-subscriptions',
  PARKING_ENTRY_DEVICES = 'parking-entry-devices',
  PARKING_RULES = 'parking-rules',
  PARKING_RULE = 'parking-rule',
  PRICE_LISTS = 'price-lists',
  PARKING_PRICE_LISTS = 'parking-price-lists',
  EV_PRICE_LISTS = 'ev-price-lists',
  SUBSCRIPTION_PRICE_LISTS = 'subscription-price-lists',
  SINGLE_EV_PRICE_LIST = 'ev-price-list',
  SINGLE_PARKING_PRICE_LIST = 'parking-price-list',
  SINGLE_SUBSCRIPTION_PRICE_LIST = 'subscription-price-list',
  PARTNER_PROMOTIONS = 'partner-promotion',
  PARTNER_ACTIVITIES = 'partner-activities',
  PARTNER_DEVICES = 'partner-devices',
  SUBSCRIPTION_ZONES = 'subscription-zones',
  HISTORY_EV = 'history-ev',
  HISTORY_PARKING = 'history-parking',
  PARKING_CALENDAR = 'parking-calendar',
}
