import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HoverableTooltip from '../../../../../components/dataTable/components/HoverableTooltip';
import EditIcon from '../../../../../components/icons/EditIcon';
import SpotCheckedParamIcon from '../../../../../components/icons/parkingSpotParams/SpotCheckedParamIcon';
import SpotCoUsersIcon from '../../../../../components/icons/parkingSpotParams/SpotCoUsersIcon';
import SpotImagesIcon from '../../../../../components/icons/parkingSpotParams/SpotImagesIcon';
import SpotRegulationsIcon from '../../../../../components/icons/parkingSpotParams/SpotRegulationsIcon';
import SpotRulesIcon from '../../../../../components/icons/parkingSpotParams/SpotRulesIcon';
import { routes } from '../../../../../static/routes';
import { colors } from '../../../../../styles/variables';

const useParkingSpotsTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const parkingSpots = [
    {
      field: 'name',
      headerName: t('parkingDetails.parkingSpots.table.number'),
      minWidth: 100,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex items-center gap-4">
            <p className="flex align-middle">{params.row.parkingSpotNumber}</p>
          </div>
        );
      },
    },
    {
      field: 'owner',
      headerName: t('parkingDetails.parkingSpots.table.owner'),
      minWidth: 170,
      flex: 2,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="w-full flex flex-col items-start">
            <p className="font-medium text-black700 text-sm">{params.row.owner.email}</p>
            <p className="font-medium text-black700 text-sm">{params.row.owner.phoneNumber}</p>
          </div>
        );
      },
    },
    {
      field: 'params',
      headerName: t('parkingDetails.parkingSpots.table.params'),
      minWidth: 170,
      flex: 2,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const hasImages = !!params.row.hasImages;
        const hasCoUsers = !!params.row.hasCoUsers;
        const hasPdfs = !!params.row.hasPdfs;
        const hasRules = !!params.row.hasRules;

        const hoveredColor = colors.blue500;
        const defaultColor = colors.grey700;

        return (
          <div className="w-full flex items-start gap-2.5">
            {hasCoUsers && (
              <HoverableTooltip
                tooltipContent={
                  <>
                    <SpotCheckedParamIcon />
                    {t('parkingDetails.parkingSpots.table.tooltips.coUsers')}
                  </>
                }
              >
                {isHovered => <SpotCoUsersIcon color={isHovered ? hoveredColor : defaultColor} />}
              </HoverableTooltip>
            )}
            {hasImages && (
              <HoverableTooltip
                tooltipContent={
                  <>
                    <SpotCheckedParamIcon />
                    {t('parkingDetails.parkingSpots.table.tooltips.images')}
                  </>
                }
              >
                {isHovered => <SpotImagesIcon color={isHovered ? hoveredColor : defaultColor} />}
              </HoverableTooltip>
            )}
            {hasPdfs && (
              <HoverableTooltip
                tooltipContent={
                  <>
                    <SpotCheckedParamIcon />
                    {t('parkingDetails.parkingSpots.table.tooltips.regulations')}
                  </>
                }
              >
                {isHovered => <SpotRegulationsIcon color={isHovered ? hoveredColor : defaultColor} />}
              </HoverableTooltip>
            )}
            {hasRules && (
              <HoverableTooltip
                tooltipContent={
                  <>
                    <SpotCheckedParamIcon />
                    {t('parkingDetails.parkingSpots.table.tooltips.rules')}
                  </>
                }
              >
                {isHovered => <SpotRulesIcon color={isHovered ? hoveredColor : defaultColor} />}
              </HoverableTooltip>
            )}
          </div>
        );
      },
    },
    {
      field: '',
      headerName: '',
      minWidth: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const spotId = params.id as string;
        const canEdit = params.row.permissions.canEdit;

        return (
          <>
            {canEdit && (
              <div className="flex w-full justify-end pl-7 py-4">
                <div className="hover:cursor-pointer" onClick={() => navigate(routes.editParkingSpot(spotId))}>
                  <EditIcon />
                </div>
              </div>
            )}
          </>
        );
      },
    },
  ];

  return { headers: parkingSpots };
};

export default useParkingSpotsTable;
