import { PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BottomActionBox } from '../../../../../components/common/bottomActionBox/BottomActionBox';
import { PrimaryButton } from '../../../../../components/common/buttons/PrimaryButton';
import MyDataTable from '../../../../../components/dataTable/MyDataTable';
import useSingleZoneParkingSpots from '../../../../../hooks/api/parkings/zones/useParkingSpots';
import useSingleZoneParkingRules from '../../../../../hooks/api/parkings/zones/useSingleZoneParkingRules';
import useSingleZoneParkingSubscriptions from '../../../../../hooks/api/parkings/zones/useSingleZoneParkingSubscriptions';
import { useIsTablet } from '../../../../../hooks/devices/useIsTablet';
import { routes } from '../../../../../static/routes';
import SingleZoneWrapper from '../components/SingleZoneWrapper';
import ParkingRulesTabs, { RuleTab } from './components/ParkingRulesTabs';
import useParkingRulesParkingTable from './hooks/useParkingRulesParkingTable';
import useParkingSubscriptionsParkingTable from './hooks/useParkingSubscriptionsParkingTable';

const SingleZoneParkingRules = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTablet } = useIsTablet();

  const params = useParams();
  const parkingId = params.parkingId as string;
  const zoneId = params.zoneId as string;

  const { parkingRules } = useSingleZoneParkingRules({ zoneId });
  const { parkingSpots } = useSingleZoneParkingSpots({ zoneId });
  const { subscriptions } = useSingleZoneParkingSubscriptions({ zoneId });

  const { headers: headersParkingRules } = useParkingRulesParkingTable({
    parkingId,
    zoneId,
    parkingSpots: parkingSpots ?? [],
  });

  const { headers: headersParkingSubscriptions } = useParkingSubscriptionsParkingTable({ zoneId });

  const [currentTab, setCurrentTab] = useState(RuleTab.Parkings);

  const submitBtnText =
    currentTab === RuleTab.Parkings
      ? t('parkingDetails.parkingRules.addRuleBtn')
      : t('parkingDetails.parkingRules.addSubscriptionBtn');
  const submitBtnIcon = <PlusIcon height={16} />;

  const submitBtnOnClick = () => {
    if (currentTab === RuleTab.Parkings) {
      navigate(routes.addParkingRule({ parkingId }));
    } else if (currentTab === RuleTab.Subscriptions) {
      navigate(routes.addSubscription({ zoneId }));
    }
  };

  return (
    <SingleZoneWrapper>
      {!isTablet && (
        <div className="flex flex-col mb-8">
          <ParkingRulesTabs
            currentTab={currentTab}
            setActiveTab={item => setCurrentTab(item)}
            allTabs={[RuleTab.Parkings, RuleTab.Subscriptions]}
          />
          <PrimaryButton onClick={submitBtnOnClick} className="self-end w-fit" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </div>
      )}

      {currentTab === RuleTab.Parkings && <MyDataTable headers={headersParkingRules} data={parkingRules ?? []} />}
      {currentTab === RuleTab.Subscriptions && (
        <MyDataTable headers={headersParkingSubscriptions} data={subscriptions ?? []} />
      )}

      {isTablet && (
        <BottomActionBox>
          <PrimaryButton onClick={submitBtnOnClick} className="w-full" prefixIcon={submitBtnIcon}>
            {submitBtnText}
          </PrimaryButton>
        </BottomActionBox>
      )}
    </SingleZoneWrapper>
  );
};

export default SingleZoneParkingRules;
